import React from 'react'

import SystemSettings from '../../components/views/settings/systemSettings'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function SystemSettingsPage() {
   return (
      <LayoutLoggedIn>
         <SystemSettings />
      </LayoutLoggedIn>
   )
}
